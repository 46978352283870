import React, { useState } from "react";
import { inject } from "mobx-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CardHeader from "../components/CardHeader/CardHeader";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import TextArea from "../components/TextArea/TextArea";
import TextField from "../components/TextField/TextField";
import { ReservationStore } from "../stores/reservation.store";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

interface CheckoutFormProps {
  reservationStore?: ReservationStore;
}

const CheckoutForm = ({ reservationStore }: CheckoutFormProps) => {
  const { t } = useTranslation();
  const currentReservation = reservationStore?.currentReservation;
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: currentReservation?.firstName,
      lastName: currentReservation?.lastName,
      email: currentReservation?.email,
      phoneNumber: currentReservation?.phoneNumber,
      note: currentReservation?.note,
    },
  });
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    let success;
    try {
      setLoading(true);
      const reservation = {
        ...currentReservation,
        ...data,
      };

      success = await reservationStore?.create(reservation);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      navigate(`/completed${window.location.search}`, { state: { success } });
    }
  };

  const onEditClicked = () => {
    const reservation = {
      ...currentReservation,
      ...getValues(),
    };

    reservationStore?.setReservation(reservation);
    navigate(-1);
  };

  const handlePhoneNumberInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    const valueWithoutSpaces = value.replace(/\s/g, "");
    setValue(name, valueWithoutSpaces);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div className="h-screen bg-light-gray">
          <div className="flex justify-center py-12 sm:px-6 lg:px-8">
            <div className="w-full lg:w-6/12 overflow-hidden border border-solid border-slate-200 rounded-md">
              <CardHeader heading={t("cardHeaderTitle")}></CardHeader>
              <div className="bg-white px-4 py-6 sm:px-6">
                <div className="grid grid-cols-6 gap-1">
                  <div className="col-span-2 text-zinc-600 text-sm">{t("numberOfPersons")}</div>
                  <div className="col-span-2 text-zinc-600 text-sm">{t("date")}</div>
                  <div className="col-span-1 text-zinc-600 text-sm">{t("checkoutForm.time")}</div>

                  <button type="button" className="col-span-1 text-zinc-600 flex justify-end" onClick={onEditClicked}>
                    <PencilSquareIcon className="w-4 h-4" />
                  </button>
                  <div className="col-span-2 font-medium">
                    {currentReservation?.numberOfPersons}
                    {currentReservation?.numberOfPersons === 1 ? ` ${t("person")}` : ` ${t("persons")}`}
                  </div>
                  <div className="col-span-2 font-medium">{currentReservation?.dateTime?.format("DD/MM/YYYY")}</div>
                  <div className="col-span-2 font-medium">{currentReservation?.dateTime?.format("HH:mm")}</div>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-8">
                    <div className="col-span-6 sm:col-span-3">
                      <TextField
                        register={register("firstName", {
                          required: true,
                        })}
                        title={t("checkoutForm.firstName")}
                      ></TextField>
                      {errors.firstName && (
                        <p className="text-red-400 text-sm mt-2">{t("checkoutForm.firstNameRequired")}</p>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <TextField
                        register={register("lastName", {
                          required: true,
                        })}
                        title={t("checkoutForm.lastName")}
                      ></TextField>
                      {errors.lastName && (
                        <p className="text-red-400 text-sm mt-2">{t("checkoutForm.lastNameRequired")}</p>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <TextField
                        register={register("email", {
                          required: { message: t("checkoutForm.emailRequired"), value: true },
                          pattern: {
                            value: /^[\w-]+((\.[\w-]+)|(\+[\w-]+))*@([\w-]+\.)+[\w-]{2,}$/,
                            message: t("checkoutForm.emailInvalid"),
                          },
                        })}
                        title={t("checkoutForm.email")}
                      ></TextField>
                      {errors.email && <p className="text-red-400 text-sm mt-2">{errors.email.message?.toString()}</p>}
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <TextField
                        register={register("phoneNumber", {
                          required: { message: t("checkoutForm.phoneNumberRequired"), value: true },
                          pattern: {
                            value: /^((\+|00)[1-9]\d{6,14}|06\d{9,10})$/,
                            message: t("checkoutForm.phoneNumberInvalid"),
                          },
                          onChange: handlePhoneNumberInputChange,
                        })}
                        title={t("checkoutForm.phoneNumber")}
                        type="tel"
                      ></TextField>
                      {errors.phoneNumber && (
                        <p className="text-red-400 text-sm mt-2">{errors.phoneNumber.message?.toString()}</p>
                      )}
                    </div>
                    <div className="col-span-6">
                      <TextArea
                        title={t("checkoutForm.note")}
                        register={register("note", {})}
                        maxLength={256}
                      ></TextArea>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-6 text-center sm:px-6">
                  <PrimaryButton className="w-6/12 flex justify-center" title={t("checkoutForm.submit")} isSubmit />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default inject("reservationStore")(CheckoutForm);
