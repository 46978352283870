import React, { useEffect, useState } from "react";
import widgetStore from "../stores/widget.store";
import { observer } from "mobx-react";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import reservationStore from "../stores/reservation.store";
import { useSearchParams } from "react-router-dom";

const PublicAction = () => {
  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [setsuccess, setSetsuccess] = useState<any>(200);

  useEffect(() => {
    widgetStore.loadWidgetSettings();
  }, []);

  return (
    <div className="h-screen w-screen overflow-hidden bg-gray-100 flex justify-center items-center">
      <div className="rounded-md shadow bg-white p-6 py-12  lg:max-w-3xl">
        <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">
          Stornierung Ihrer Reservierung bei {widgetStore.widget?.restaurant.name}
        </h2>
        {!isLoaded && (
          <>
            <p className="mt-6 text-lg leading-8 text-black-300">Durch Klicken auf "Stornierung bestätigen" wird Ihre Reservierung storniert:</p>
            <div className="flex justify-end items-end">
              <PrimaryButton
                title="Stornierung bestätigen"
                className="bg-red-500 mt-5 h-11"
                onClick={async () => {
                  const isSuccess = await reservationStore.cancelReservation(searchParams.get("key") || "");
                  setIsLoaded(true);
                  setSetsuccess(isSuccess);
                }}
              />
            </div>
          </>
        )}
        {isLoaded && setsuccess === 200 && (
          <>
            <p className="mt-6 text-lg leading-8 text-black-300">Die Reservierung wurde erfolgreich storniert.</p>
          </>
        )}
        {isLoaded && setsuccess === 410 && (
          <>
            <p className="mt-6 text-lg leading-8 text-black-300">Die Reservierung wurde bereits erfolgreich storniert.</p>
          </>
        )}
        {isLoaded && setsuccess === 401 && (
          <>
            <p className="mt-6 text-lg leading-8 text-black-300">
              Die Reservierung konnte nicht storniert werden. Bitte überprüfen sie den Link.
            </p>
          </>
        )}
        {isLoaded && setsuccess === 400 && (
          <>
            <p className="mt-6 text-lg leading-8 text-black-300">
              Die Reservierung konnte leider nicht mehr storniert werden. Wir bitten Sie die Stornierung telefonisch durchzuführen.
            </p>
            <p>
              <div className="flex justify-start mt-5">
{/*                <div className="flex-grow">
                  <h3 className="text-xl font-bold tracking-tight text-black sm:text-xl">E-Mail:</h3>
                  <a href={`mailto:${widgetStore.widget?.restaurant.email}`}>{widgetStore.widget?.restaurant.email}</a>
                </div>*/}
                <div className="flex-grow">
                  <h3 className="text-xl font-bold tracking-tight text-black sm:text-xl">Telefon:</h3>
                  <a href={`tel:${widgetStore.widget?.restaurant.phoneNumber}`}>
                    {widgetStore.widget?.restaurant.phoneNumber}
                  </a>
                </div>
              </div>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(PublicAction);
